@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat";
  scroll-behavior: smooth;
}

body{
  transition: 0.4s;
  margin-right:5%;
}

.profile-image{
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.darkMode{
  background-color: #121212;
  color: white;
}

.left{
  justify-content: left;
  text-align: left;
  margin-left: 5%;
}

.right{
  float: right;
  text-align: left;
  margin-right:5%;
}

.header{
  top: 5%;
  left: 5%;
  width: 30%;
  position: fixed;
}

.name{
  font-size: 4em;
  font-weight: 200;
  margin-left: -0.1em;
}

.name_label{
  font-size: 1.5em;
  font-weight: 200;
}

.navbar{
  margin-top: 50px;
}

a{
  text-decoration: none;
  color: black;
  font-weight: 500;
  width:fit-content;
}

a:visted{
  color: black;
}

a:hover{
  color: rgb(127,127,127);
}

.darkMode a{
  color: white;
  transition: 0.4s;
}

.darkMode h2 a{
  color: rgb(144, 122, 250);
  transition: 0.4s;
}

.darkMode a:hover{
  color: rgb(78, 78, 78);
}

ol{
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

ol li{
  list-style: none;
}

.image{
  margin-top: 20px;
}

.content{
  margin-top: 300px;
  margin-bottom: 100px;
  margin-left: 33%;
}

.hidden{
  display: none;
}

.inlineImg{
  display: inline;
  height: 30px;
  width: auto;
}

.pageMode{
  margin-top: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #404040;
}

input:focus + .slider {
  box-shadow: 0 0 1px #404040;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

h2{
  color: #53f;
}

h2 a{
  color: #53f;
  font-weight: 900;
}

.darkMode h2{
  color: rgb(144, 122, 250);
}

#home{
  font-size: 3rem;
}

#home-name{
  color: #53f;
}

.darkMode #home-name{
  color: rgb(144, 122, 250);
}

#resume h2{
  margin-top: 20px;
  font-weight: 600;
}

#projects h2{
  margin-top: 20px;
  font-weight: 600;
}

#projects h3{
  margin-bottom: 5px;
  font-weight: 500;
}

#resume h3{
  margin-top: 10px;
  font-weight: 600;
}

#resume ul{
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  row-gap:5px;
}

#projects ul{
  row-gap: 2px;
  margin-left: 20px;
}

.contact-label{
  color: #53f;
}

.darkMode .contact-label{
  color: rgb(144, 122, 250);
}

#short-name{
  display: none;
}

@media only screen and (max-width: 1700px) {
  #long-name{
    width: 400px;
  }
}

@media only screen and (max-width: 1300px) {
  #long-name{
    display:none;
  }

  #short-name{
    display:block;
  }
}

@media only screen and (max-width: 930px) {
  #long-name{
    display:block;
  }

  #short-name{
    display: none;
  }

  .header{
    position:fixed;
    background-color: white;
    width: 930px;
    margin-top: -100px;
    padding-bottom: 20px;
    padding-top: 100px;
    transition: 0.4s;
    opacity: .90;
  }

  .darkMode .header{
    background-color: #121212;
  }

  .image{
    display: none;
  }

  ol{
    flex-direction: row;
  }

  ol li{
    margin-left: 1em;
  }

  .name{
    text-wrap: nowrap;
  }

  .content{
    margin-top: 300px;
    margin-left: 10%;
  }

  .pageMode{
    margin-left: 2%;
  }
}

